module.exports = new Promise((resolve, reject) => {

      const url = 'https://viewpoint-gateway.azurewebsites.net/loto/ui/remoteEntry.js?v=1727783365929';
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'json';      
      xhr.timeout = 10000;
      xhr.onload = function() {
        var status = xhr.status;
        if (status === 200) {

          const script = document.createElement('script');
          script.src = 'https://viewpoint-gateway.azurewebsites.net/loto/ui/remoteEntry.js?v=1727783365929';
           script.onload = () => {
            const proxy = {
              get: (request) => window.loto.get(request),
              init: (arg) => {
                try {
                  return window.loto.init(arg)
                } catch(e) {
                  console.log('remote container already initialized')
                }
              }
            }
            resolve(proxy)
          }
          document.head.appendChild(script);

        } else {
          console.log("not 200");
        }
      };
      xhr.onabort = ()=>{console.warn("dLOTO Micro-Frontend is not available (abort)");reject("");}
      xhr.onerror = ()=>{console.warn("dLOTO Micro-Frontend is not available (reject)");reject("")}
      xhr.ontimeout= ()=>{console.warn("dLOTO Micro-Frontend is not available (timeout)"); reject("")}
      xhr.send();


    
  });